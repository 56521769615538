import React from "react";

import { useTheme } from "../../../utils/Theme";

import { SVGProps } from "./SVG.props";

const WeatherhSvg = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, fill, stroke } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={height || "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_3221_10163)">
        <path
          fill="#fff"
          stroke={stroke || theme.btn.text.secondary}
          d="M7.947 11.937l.373-.035.07-.367c.436-2.25 2.424-3.935 4.75-3.935s4.314 1.685 4.75 3.935l.07.37.375.033a3.25 3.25 0 012.945 3.232 3.247 3.247 0 01-3.24 3.24h-9.8A3.247 3.247 0 015 15.17c0-1.688 1.3-3.076 2.947-3.233zm.864.263h0v.005c-.006.121-.105.225-.251.225h-.32a2.743 2.743 0 00-2.74 2.74 2.75 2.75 0 002.74 2.74h9.8a2.743 2.743 0 002.74-2.74 2.75 2.75 0 00-2.74-2.74h-.32a.253.253 0 01-.251-.236C17.32 9.905 15.426 8.11 13.14 8.11a4.35 4.35 0 00-4.33 4.09z"
        ></path>
        <path
          fill={fill || "#000"}
          d="M5.85 11.93c-.26 0-.51-.13-.65-.37-.09-.15-.17-.31-.24-.47A4.084 4.084 0 0110.82 6c.36.21.47.67.26 1.03-.21.35-.67.47-1.03.26-.4-.24-.86-.37-1.33-.37a2.586 2.586 0 00-2.38 3.59c.04.1.09.2.15.3a.752.752 0 01-.65 1.13l.01-.01zM6.5 5.64c-.27 0-.54-.15-.67-.41L5.3 4.18a.75.75 0 01.33-1.01.75.75 0 011.01.33l.53 1.05a.75.75 0 01-.67 1.09zM3.89 8.58c-.06 0-.13 0-.19-.03l-1.14-.3a.75.75 0 11.39-1.45l1.14.3c.4.11.64.52.53.92-.09.34-.39.56-.72.56h-.01zM3.09 13c-.26 0-.51-.13-.65-.38a.746.746 0 01.27-1.02l1.02-.59a.746.746 0 01.75 1.29l-1.02.59c-.12.07-.25.1-.37.1V13zM10.99 5.57c-.1 0-.21-.02-.31-.07a.746.746 0 01-.37-.99l.49-1.07c.17-.38.62-.54.99-.37.38.17.54.62.37.99l-.49 1.07a.74.74 0 01-.68.44zM8.6 19.2c-.44 0-.81.36-.81.81 0 .45.36.81.81.81.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81zM13.05 19.2c-.44 0-.81.36-.81.81 0 .45.36.81.81.81.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81zM10.83 20.24c-.44 0-.81.36-.81.81 0 .45.36.81.81.81.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81zM15.28 20.24c-.44 0-.81.36-.81.81 0 .45.36.81.81.81.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81zM17.51 19.2c-.45 0-.81.36-.81.81 0 .45.36.81.81.81.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3221_10163">
          <path
            fill="#fff"
            d="M0 0H19.78V18.85H0z"
            transform="translate(2 3)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default WeatherhSvg;
