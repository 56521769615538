import styled from "@emotion/styled";
import { theme } from "../../../utils/Theme";

export const Container = styled.div`
background-color: ${theme.brand.accent};
margin-top: 2rem;
padding-top: 2rem;
border-top: 1px solid white
`

