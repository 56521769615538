import React from "react";

import { useTheme } from "../../../utils/Theme";

import { SVGProps } from "./SVG.props";

const Soil = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 30}
      height={height || 28}
      fill="none"
      {...props}
    >
      <path
        fill="#57CB62"
        d="M27.908 24.87a4.905 4.905 0 0 0-2.28-1.477 4.884 4.884 0 0 0-2.563-.099c-.154.035-.289.008-.377-.127-.05-.08-.076-.177-.102-.27a6.68 6.68 0 0 0-1.002-2.168c-1.24-1.748-2.923-2.766-5.047-3.015a6.313 6.313 0 0 0-.446-.037 21.62 21.62 0 0 1-.06-.42c-.363-2.855-.311-5.685.608-8.445.603-1.81 1.564-3.374 3.248-4.374.402-.24.852-.4 1.28-.596-2.215 1.88-3.765 4.055-3.781 7.132.284-.04.5-.067.716-.102 3.045-.511 5.339-2.042 6.302-5.085.579-1.83.883-3.747 1.347-5.787-1.438.177-2.707.282-3.956.497-4.023.693-6.332 2.908-7.208 6.872-.15.675-.225 1.363-.343 2.106-.086-.113-.135-.167-.172-.228-1.363-2.17-3.344-3.285-5.86-3.545-1.627-.168-3.21.07-4.794.368v.223c.078.16.17.314.234.48.374.95.693 1.924 1.123 2.847.683 1.463 1.795 2.487 3.344 2.991 1.472.48 2.979.496 4.544.374-.408-2.155-1.74-3.552-3.494-4.636 2.007.612 3.428 1.87 4.235 3.802.52 1.243.666 2.55.598 3.885-.033.64-.1 1.277-.155 1.919-.41.128-.811.299-1.204.511-1.347.733-2.387 1.8-3.2 3.094-.093.15-.2.228-.38.244-1.267.11-2.232.717-2.896 1.807-.086.142-.212.195-.376.18A4.972 4.972 0 0 0 4 23.955c-1.577.433-2.75 1.387-3.508 2.84C.296 27.168.17 27.58 0 28h29.147c.01-.028.016-.038.018-.049a.165.165 0 0 0 0-.051 5.022 5.022 0 0 0-1.258-3.033v.004Z"
      />
    </svg>
  );
};

export default Soil;
