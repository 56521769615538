import React from "react";

import { useTheme } from "../../../utils/Theme";

import { SVGProps } from "./SVG.props";

const Suggestion = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 40}
      height={height || 40}
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#6C57ED" />
      <g clip-path="url(#clip0_1393_41716)">
        <path
          d="M28.6433 11.7856H11.3576C10.9408 11.7856 10.5411 11.9512 10.2464 12.2459C9.95169 12.5406 9.78613 12.9403 9.78613 13.3571V26.7142C9.78613 27.131 9.95169 27.5307 10.2464 27.8254C10.5411 28.1201 10.9408 28.2856 11.3576 28.2856H14.5004V32.2142L19.4033 28.2856H28.6433C29.06 28.2856 29.4597 28.1201 29.7544 27.8254C30.0491 27.5307 30.2147 27.131 30.2147 26.7142V13.3571C30.2147 12.9403 30.0491 12.5406 29.7544 12.2459C29.4597 11.9512 29.06 11.7856 28.6433 11.7856Z"
          stroke="white"
          stroke-width="1.42857"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.3996 15.3045C24.3996 14.7498 23.9769 14.2998 23.4559 14.2998H19.9883C17.5651 14.2998 15.5996 16.3911 15.5996 18.9721C15.5996 18.9865 15.5996 18.9997 15.5996 19.013V18.9504L20.0301 23.6431C22.4341 23.6179 24.3759 21.5374 24.3759 18.9721C24.3759 18.9636 24.3759 18.9552 24.3759 18.9468L24.3996 18.9721V15.3045ZM22.3652 18.9624C22.3652 18.9624 22.3652 18.9697 22.3652 18.9733C22.3652 20.3414 21.3242 21.4496 20.0392 21.4496C19.8775 21.4496 19.7204 21.4315 19.569 21.399V20.1585C19.569 19.8661 19.3463 19.6291 19.0717 19.6291H17.7957C17.7414 19.4197 17.712 19.1995 17.712 18.9721C17.712 17.604 18.753 16.4958 20.038 16.4958C20.0923 16.4958 20.1454 16.4982 20.1985 16.5018H21.8668C22.1414 16.5018 22.3641 16.7388 22.3641 17.0312V18.9612L22.3652 18.9624Z"
          fill="white"
        />
        <path
          d="M17.7244 21.3954H15.6019V21.2041H15.5996V26.3998H17.7244V23.6443V22.9753V21.3954Z"
          fill="white"
        />
        <path
          d="M20.0313 23.6434L15.5996 18.9507V21.2044H15.6007V21.3957H17.7233V23.6434H20.0313ZM17.7233 21.2285L17.7007 21.2032H17.7233V21.2285Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1393_41716">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(9 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Suggestion;
